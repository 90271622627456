import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import type { LoadRegionConfig } from 'web/src/modules/sportline/submodules/region/types';
import type {
  LeagueElement,
  OutrightSportEvent,
  Region,
  RegionElement,
  Sport,
  SportElement,
  SportEvent,
} from 'web/src/modules/sportline/types';
import type {
  CoreSportlineFetchOptions,
  GetRegionResponse,
  GetSportEventsResponse,
} from 'web/src/modules/sportline/types/rest';
import { useBroadcastSelected } from 'web/src/modules/sportline/composables/broadcast';
import { useGlobalSportlineEvents } from 'web/src/modules/sportline/composables/core';
import { useGetSportlineEntityFactory } from 'web/src/modules/sportline/composables/factories';
import {
  useIsMarketTypesSelectionEnabledRef,
  useParseSportlineSettingsRef,
} from 'web/src/modules/sportline/composables/settings';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import {
  useSportlineEventsChangesListeners,
  useSportlinePageIdentifier,
} from 'web/src/modules/sportline/store/composables';
import { useCustomerFavoritesService } from 'web/src/modules/sportline/submodules/favorites/composables';
import { CustomerFavoritesIdsBackgroundUpdateKey } from 'web/src/modules/sportline/submodules/favorites/enums';
import { useSyncSportline } from 'web/src/modules/sportline/submodules/sync-sportline';
import { useRegionEventsSportlineUpdateTimeout } from 'web/src/modules/sportline/submodules/update-timeout';
import { filterSportsListEvents } from 'web/src/modules/sportline/utils';
import { normalizeEventsChangesResponseToDefaultResponse } from 'web/src/modules/sportline/utils/response';
import { SportlineFactory } from 'web/src/modules/sportline/utils/rest';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
import useAwaitConditionQueue from 'web/src/utils/store/composables/useAwaitConditionQueue';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';

export const useRegionPageStore = defineStore('sportline-region-page-store', () => {
  const {
    onInit: onGlobalSportlineEventsInit,
  } = useGlobalSportlineEvents();
  const { timeout: regionEventsUpdateInterval } = useRegionEventsSportlineUpdateTimeout();
  const parseSportlineSettings = useParseSportlineSettingsRef();
  const isMarketTypesSelectionEnabled = useIsMarketTypesSelectionEnabledRef();
  const apiService = useSportlineApiService();
  const broadcastSelected = useBroadcastSelected();
  const customerFavoritesService = useCustomerFavoritesService();
  const {
    pageIdentifier,
    setPageIdentifier,
  } = useSportlinePageIdentifier();

  const configForRegionLoading = ref<LoadRegionConfig>({});
  const lastLoadedRegionId = ref<Maybe<string>>(null);
  const isLoaded = ref(false);
  const isRegionDataLoaded = ref(false);
  const isLoadingIndicatorShown = ref(false);
  const rawEventsResponse = ref<Maybe<GetSportEventsResponse> | false>(null);
  const rawRegionResponse = ref<Maybe<GetRegionResponse> | false>(null);

  const currentRegionLoadingConfigId = computed<Maybe<string>>(() => configForRegionLoading.value.id ?? null);
  /** Is page ready to show (we have some data) */
  const isReady = computed<boolean>(() => rawEventsResponse.value !== null || rawRegionResponse.value !== null);
  /** Get actual sports with events tree */
  const sportElementsList = computed<Maybe<SportElement[]>>(() => (
    rawEventsResponse.value
      ? (new SportlineFactory<SportEvent>(rawEventsResponse.value, parseSportlineSettings.value)).build()
      : null
  ));
  const outrightEvents = computed<SportElement<OutrightSportEvent>[]>(() => (
    filterSportsListEvents<OutrightSportEvent>(
      (sportElementsList.value ?? []) as SportElement<OutrightSportEvent>[],
      (item) => item.sportEvent.isOutright,
    )
  ));

  const {
    regionElement: regionData,
  } = useGetSportlineEntityFactory({ rawRegionResponse });

  const sportElement = computed<Maybe<SportElement>>(() => (sportElementsList.value?.[0] ?? null));
  const regionElement = computed<Maybe<RegionElement>>(() => sportElement.value?.regions[0] ?? regionData.value);
  const leagueElements = computed<LeagueElement[]>(() => (
    (regionElement.value?.leagues ?? [])
      .filter((leagueElement) => leagueElement.sportEvents.length > 0
        || leagueElement.outrightEvents.length > 0)
  ));
  const containEvents = computed<boolean>(() => (leagueElements.value.length > 0));
  const region = computed<Maybe<Region>>(() => (regionElement.value?.region ?? null));
  const sport = computed<Maybe<Sport>>(() => (sportElement.value?.sport ?? null));

  function setConfigForRegionLoading(config: LoadRegionConfig): void {
    configForRegionLoading.value = config;
  }

  function clearRegion(): void {
    setConfigForRegionLoading({});
    rawEventsResponse.value = null;
    isLoadingIndicatorShown.value = false;
  }

  function fetchRegion({ regionId, silent }: {
    regionId: string;
    silent?: boolean;
  }): Promise<Maybe<GetRegionResponse>> {
    return apiService.loadRegion({ id: regionId, silent });
  }

  const lastUpdate = new BackgroundUpdateStopwatch<['regionEvents', 'regionData']>({
    regionEvents: async ({ silent }: CoreSportlineFetchOptions) => {
      const regionId = currentRegionLoadingConfigId.value;
      const isUpdateRequest = regionId === lastLoadedRegionId.value;

      if (!regionId) {
        return;
      }

      isLoaded.value = false;

      if (!isUpdateRequest) {
        isLoadingIndicatorShown.value = true;
      }

      try {
        const response = await apiService.loadRegionEvents({
          regionId,
          // eslint-disable-next-line ts/prefer-nullish-coalescing
          vTag: isUpdateRequest ? (rawEventsResponse.value || null)?.vtag : undefined,
          silent,
        });

        // user went away from the page
        if (regionId !== currentRegionLoadingConfigId.value) {
          return;
        }

        rawEventsResponse.value = normalizeEventsChangesResponseToDefaultResponse(response);
        broadcastSelected.updateDataInStorageByResponse({ response });
        lastUpdate.update('regionEvents');
        lastLoadedRegionId.value = regionId;
      } catch (error: unknown) {
        rawEventsResponse.value = false;
        lastLoadedRegionId.value = regionId;
        throw error;
      } finally {
        isLoaded.value = true;
        isLoadingIndicatorShown.value = false;
      }
    },
    regionData: async ({ silent }: CoreSportlineFetchOptions) => {
      const regionId = currentRegionLoadingConfigId.value;
      try {
        if (!regionId) {
          return;
        }
        isRegionDataLoaded.value = false;

        const response = await fetchRegion({ regionId, silent });
        if (!response) {
          return;
        }

        rawRegionResponse.value = response;
        lastUpdate.update('regionData');
      } catch (error: unknown) {
        rawRegionResponse.value = false;
        throw error;
      } finally {
        isRegionDataLoaded.value = true;
        lastLoadedRegionId.value = regionId;
      }
    },
  });

  const {
    initialRequests,
    syncState,
    syncBackgroundRequests,
    setBackgroundUpdateEnabled,
  } = useBackgroundRequestsLifeCycle({
    lastUpdate,
    updateInterval: regionEventsUpdateInterval,
    onEnableChanged(value: boolean): void {
      void customerFavoritesService.setIsBackgroundIdsUpdateAvailable(
        CustomerFavoritesIdsBackgroundUpdateKey.REGION,
        value,
      );
    },
  });

  const {
    awaitCondition: awaitStateIsLoaded,
  } = useAwaitConditionQueue({
    source: 'Sportline region page is ready',
    value: isLoaded,
    condition: (value: boolean) => value,
  });

  const {
    awaitCondition: awaitLeavePage,
  } = useAwaitConditionQueue({
    source: 'Leave sportline region page',
    value: computed(() => pageIdentifier.value.regionId),
    condition: (value: Optional<string>, oldValue: Optional<string>) => !value || (!!oldValue && value !== oldValue),
  });

  const {
    onInit: onSportlineEventsChangesListenersInit,
  } = useSportlineEventsChangesListeners({
    setResponse(response: GetSportEventsResponse): void {
      rawEventsResponse.value = response;
    },
    getResponse(): Maybe<GetSportEventsResponse> {
      // eslint-disable-next-line ts/prefer-nullish-coalescing
      return rawEventsResponse.value || null;
    },
  });

  function onInit(): void {
    useSyncSportline(async (silent: boolean): Promise<void> => {
      await syncState({ silent });
    }, regionEventsUpdateInterval);
  }

  // init
  onGlobalSportlineEventsInit();
  onSportlineEventsChangesListenersInit();
  onInit();

  return {
    setConfigForRegionLoading,
    pageIdentifier,
    setPageIdentifier,
    awaitStateIsLoaded,
    awaitLeavePage,
    clearRegion,
    isLoaded,
    isReady,
    isLoadingIndicatorShown,
    isMarketTypesSelectionEnabled,
    sportElementsList,
    outrightEvents,
    containEvents,
    region,
    sport,
    lastLoadedRegionId,
    currentRegionLoadingConfigId,
    initialRequests,
    syncBackgroundRequests,
    setBackgroundUpdateEnabled,
  };
});
